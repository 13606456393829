*{
  letter-spacing: 0.5px; 
}
/*
* {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
}
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.katilai{
  display: grid;
}
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4px;
  padding-left: 0px;
  position: relative;
  width: 1676px;
}

.card {
  justify-self: stretch ;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 556px;
  height: 752px;
  padding-left: 0px;
  margin-right: 0px;
  padding-top: 6px;
}

.card > .feature {
  position: absolute;
  color: #e15303;
  font-family: 'Poppins', sans-serif;
  text-decoration: underline 1px;
  text-underline-offset: 8px;
  font-size: 16px;
  top: 4.4%;
  left: 80px;
  font-weight: 600;
}

.card > .katilasName {
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  top: 9.2%;
  left: 80px;
}

.card > img {
  width: 100%;
  
}
.card > img:hover{
  cursor: pointer;
}

.card > .dataTop {
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 1.5em;
  top: 19.2%;
  left: 80px;
}

.Nav a {
  font-size: 12px !important;
  color: #a7a7a7;
}

.Nav span:last-child a {
  font-size: 12px !important;
  color: black;
}

.dataTop > .dataTopType {
  text-align: left;
  font-weight: 500;
  position: absolute;
  top: -18px;
  width: 330px;
}

.dataTop > .dataTopSpecs {
  color: #666666;
  text-align: left;
  font-weight: 500;
  line-height: 1em;
}
.dataTopClass{
  font-weight: 500;
}

.dataTopSpecs > sup {
  font-size: 10px;
}

.sutikimasCheckbox {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.mainFeaturesTable {
  height: 50px;
  position: absolute;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1em;
  top: 81%;
  left: 80px;
  border-collapse: collapse;
}

table .Fuel1 {
  border-right: 1px solid gray;
  color: #666666;
  padding-right: 12px;
}

table .Fuel2 {
  border-right: 1px solid gray;
  color: #666666;
  padding-right: 12px;
  padding-left: 12px;
  /*white-space: nowrap;*/
}

table .Fuel1, .Fuel2 {
  font-size: 11px;
  max-width: 100px;
  overflow-wrap: break-word;
}

thead{
  position: relative;
}

table .Kuras {
  position: absolute;
  left: 0px;
  top: -24px;
  font-size: 14px;
  font-weight: 600;
}

table .BigData {
  padding-right: 15px;
  padding-left: 15px;
  border-left: 1px solid gray;
  position: relative;
  /* min-width: 120px; */
  text-align: center;
}

.DetailsRight table .BigData, .OrderRight table .BigData{
  text-align: inherit;
  padding-right: 12px;
}

.DetailsRight table .BigData ~.BigData, .OrderRight table .BigData ~.BigData{
  padding-left: 12px;
}

.TableSpecs {
  padding-left: 30px;
  font-size: 14px;
}

.TableSpecs ~ .TableSpecs {
  padding-left: 14px;
}

table .BigDataNoEff:first-child{
  padding-left: 0;
  border-left: none;
}

table .BigDataMobile {
  display: none;
  padding-right: 8px;
  padding-left: 8px;
  border-left: 1px solid gray;
  min-width: 120px;
}

table .BigNumber {
  font-size: 64px;
  position: absolute;
  bottom: 20px;
}

.NavAndEff {
  position: relative;
}

.DetailsBigData {
  float: left;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.DetailsBigData .BigNumber{
  left: 10px;
  top: -20px;
}

table .BigText {
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  left: 52px;
  top: 0px;
}

.DetailsBigData .BigText{
  left: 56px;
  top: -8px;
}

table .SmallText {
  font-size: 10px;
  font-weight: 700;
  position: absolute;
  left: 54px;
  top: 24px;
  line-height: 1.2em;
}

.DetailsBigData .SmallText{
  left: 57px;
  top: 24px;
}

.card > .DetailsButton {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.DetailsButton {
  width: 260px;
  height: 55px;
  background-color: #e15303;
  color: white;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0px 0px 16px 1px #e15303;
  border: none;
  cursor: pointer;
}

.DetailsButton:hover {
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.MainInfo > .feature {
  color: #e15303;
  font-family: 'Poppins', sans-serif;
  text-decoration: underline 1px;
  text-underline-offset: 8px;
  font-size: 16px;
  font-weight: 600;
}

.MainInfo > .katilasName {
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-weight: 600;
}

.MainInfo > .dataTop {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 600;
}

.DetailsLeft .katilasImage {
  width: 628px;
  height: 500px;
  /* object-fit: cover; */
  /* object-position: 30% 90%; */
}

.katilasImageDiv{
  position: relative;
  padding-bottom: 0px;
}

.katilasImageDiv .Circle {
    position: absolute;
}

.ImgNumber {
  font-size: 12px;
  color: #666666;
}

.IconsDiv {
  margin-top: 50px;
  width: 600px;
  height: 120px;
  display: flex;
}

.iconsDivDown {
  margin-top: 150px;
  z-index: 2;
}

.iconsDivDown * {
  z-index: 2;
}

.IconBox {
  margin-top: 4px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100px;
  height: 100px;
  border-left: 1px solid lightgray;
  cursor: pointer;
}

.IconBox:first-child {
  border: none;
  padding-left: 0;
}

.IconBox img {
  overflow: visible;
  max-height: 100px;
}

.Circle {
  height: 22px;
  width: 22px;
  background-color: #F5F5F5;
  border-radius: 50%;
  position: relative;
  line-height: 23px;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  /* z-index: 1005; */
  display: none;
}

.ActiveCircle {
  color: #e15303;
}

.filter-orange{
  filter: invert(39%) sepia(91%) saturate(3826%) hue-rotate(10deg) brightness(97%) contrast(98%);
}

.DetailsSection {
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  padding-bottom: 60px;
}

.DetailsLeft {
  float: left;
  padding-right: 180px;
  padding-top: 20px;
}

.Nav {
  position: absolute;
  color: #666666;
  font-size: 12px;
  top: 30px;
  left: 100px;
  float: left;
  
  padding-right: 80px;
}
.before-katilai .Nav, .aboutText1 .Nav{
  min-width: 90%;
}

.MainInfo {
  padding-top: 0px;
}

.MainInfo > .feature {
  padding-bottom: 14px;
}

.DetailsRight {
  float: left;
  display: flex;
  flex-direction: column;
}

.EffClassBig {
  position: absolute;
  top: 0px;
}

.EffClassText {
  position: absolute;
  top: 34px;
  color: #666666;
  font-size: 12px;
}

/*.DetailsBigData td {
  padding-bottom: 60px;
}*/

.Wrapper {
  overflow: hidden;
  padding-left: 100px;
  padding-right: 70px;
  padding-top: 72px;
}

.tableWho {
  margin-top: 10px;
  max-width: 916px;
  align-self:flex-start;
}

.tableWho tbody > tr {
  line-height: 21px;
  font-weight: 500;
}

.tableWho tbody tr:first-of-type{
  line-height: 14px;
}

.magnifier-div{
  border-radius: 50%;
  box-shadow: white 0px 0px 20px 8px
}

.DetailsBottom {
  display: flex;
  flex-direction: column;
  min-height: 380px;
  padding-left: 100px;
  margin-top: 50px;

  position: relative;
}

.KuroTipai {
  display: flex;
  flex-direction: row;
  margin-top: 22px;
  margin-bottom: 20px;
  font-size: 14px;
}

.GrayTableData {
  color: #666666;
  font-size: 14px;
}

.DataTableHeader {
  border-bottom: 1px solid gray;
  padding-bottom: 8px;
  font-weight: 600;
}

.Powers{
  padding-left: 30px; 
}

.Powers ~ .Powers{
  padding-left: 14px; 
}

.Wrapper table {
  border-collapse: collapse;
}

.BetweenTables {
  padding-top: 23px;
  margin-bottom: 23px;
  border-bottom: 1px solid gray;
  width: 200px;
}

.AdditionalDataTable {
  margin-bottom: 40px;
  font-weight: 500;
  line-height: 21px;
}

.DescriptionButton{
  background-color: #F5F5F5;
  border-right: 1px solid #c8c8c8;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #707070;
  border-left: none;
  color: black;
  padding: 20px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
}

.DescriptionButtonActive{
  background-color: #F5F5F5;
  border-right: 1px solid #707070;
  border-top: 1px solid #707070;
  border-bottom: none;
  border-left: 1px solid #707070;
  color: black;
  padding: 20px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
}

.ExtraTabs > div {
  margin-top: 40px;
}

.ExtraTabs h3 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.ExtraTabs h4 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}

.ExtraAprašymas {
  font-size: 16px;
  max-width: 800px;
}

.ExtraDegiklis {
  font-size: 16px;
  max-width: 800px;
}

.ExtraPajungimas {
  font-size: 16px;
  max-width: 800px;
}

.ExtraTabs {
  position: absolute;
  top: 50px;
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: fit-content;
  gap: 0 60px;
}

.ExtraTabs > .Price {
  width: 350px;
  position: relative;
  left: 310px;
  top: -284px;
}

.DetailsRight .Price {
  position: relative;
  left: 70.5%;
}

form .Price {
  position: relative;
  bottom: 70px;
  left: 454px;
}

.link-div {
  position: relative;
  bottom: 20px;
  height: 55px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  flex-wrap: wrap;
}

.Price > .link-div .OrangeLink {
  position: relative;
  margin-top: 0;
  /* bottom: 20px; */
}

.Price > .link-div .OrangeLink2 {
  position: relative;
  /* top: 10px; */
  /* right: 247px; */
}

.Price > .OrangeLink {
  position: relative;
  top: 18px;
  right: 242px;
}

.ExtraButtonsUnderline {
  width: 54%;
  border-bottom: 1px solid #707070;
  float: left;
  position: relative;
  top: 62px;
}

.ButtonsDiv {
  float: left;
}

.FormOrangeP {
  font-size: 12px;
  color: #e15303;
}

.OrangePDiv{
  display: flex;
  width: 800px;
  text-align: left;
}

.ConditionalDivs {
  min-width: 800px;
  width: 65%;
}

.ConditionalDivs > div {
  padding-top: 16px;
}

.Price > div > p, h2 {
  font-weight: 600;
}

.ExtraMatmenys {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 60px;
}

/* .ExtraMatmenys > table {
  table-layout: fixed;
  width: 500px;
  height: 140px;
} */

.ExtraMatmenys table {
  max-width: 500px;
}

.ExtraMatmenys img {
  width: 628px;
  height: 400px;
  max-width: 628px;
  max-height: 400px;
  padding-bottom: 10px;
}

.ExtraDegiklis img {
  /* width: 216px;
  height: 130px; */
  width: 162px;
  height: 98px;
}

.ExtraDegiklis > .DegiklisData {
  display: flex;
  gap: 10px 30px;
  margin-top: 26px;
}

.ExtraPajungimas > div{
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 30px;
  margin-top: 50px;
}

.ExtraPajungimas > div > div{
  border-left: 1px solid gray;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}

.FormInputFields > * {
  margin: 4px;
}

.FormInputFields {
  margin-top: 140px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.InputFieldRow{
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: 980px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.FormSelects > * {
  margin: 4px;
}

.FormSelects select {
  width: 475px;
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #666666;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
  padding-top: 20px;
}

.TextAreaFieldDiv {
  max-height: 200px;
}

.InputFieldDiv input {
  border: none;
  background-color: inherit;
  border-bottom: 1px solid #666666;
  width: 475px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.InputFieldDiv input::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.TextAreaFieldDiv textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.TextAreaFieldDiv textarea {
  max-height: 180px;
  max-width: 974px;
  min-height: 30px;
  min-width: 200px;
  height: 30px;
  width: 974px;
  margin-top: 10px;
  padding-bottom: 6px;
  border: none;
  background-color: inherit;
  border-bottom: 1px solid #666666;
  font-size: 18px;
  font-weight: 600;
}

.NavAndEff .Nav.Mobile_order_nav{
  display: none;
}

.FormInputFields label {
  margin-top: 40px;
}

.CloseOrderButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  border: none;
  position: relative;
  left: 400px;
  cursor: pointer;
}

.topNav .CloseOrderButton{
  right: 367px;
  left: auto;
  position: absolute;
  top: 8px;
  z-index: 1005;
  font-size: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.InputFieldDiv {
  height: 40px
}

.ValidationError {
  font-size: 12px;
  color: #e15303;
  margin-top: 2px;
}

.InputFieldDiv > .ValidationError{
  position: relative;
  bottom: 84px;
}

label > .ValidationError{
  position: relative;
  bottom: 20px;
}

.asterisk{
  width: 20px;
}

.OrderRight {
  float: left;
}

.OrderRight .BigData{
  text-align: inherit;
}

.main-katilai {
  padding-top: 80px;
  padding-bottom: 50px;
  font-size: 36px;
  font-weight: 600;
}

.desktopImage{
  display: inherit;
}
.mobileImage{
  display: none;
}

.before-katilai {
  margin-bottom: 35px;
  max-width: 300px;
}

.dropdown-div {
  display: flex;
  height: 55px;
  z-index: 2;
  position: relative;
  width: 400px;
  left: 160px;
  top: -45px;
  
}
.dropdown-div .dropdown{

  border: solid 1px black;
  width: 400px;
  height: 55px;

  justify-content: space-between;
}
.dropdown .arrow{
  margin-right: 30px;
}
.dropdown > #dropdown-basic{
  padding-left: 50px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  width: 400px;
  height: 55px;
  display: flex;
  justify-content: space-between;
}


.dropdown-item {
  color: black;
  border-bottom: 1px solid lightgray;
  /* margin-top: 10px;
  padding-bottom: 4px; */
  height: 55px;
  padding-left: 50px;
  text-align: justify;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.dropdown-item:last-child {
  color: black;
  border: none;
}

.dropdown-item:hover {
  color: #e15303 !important;
}

.dropdown-item:visited {
  color: black;
}

.dropdown-menu {
  flex-direction: column;
  z-index: 2;
  position: relative;
  background-color: white;
  width: 400px;
  display: none;
  background-color: #FAFAFA;
  border: solid 1px black;
  transform: translate(0px,55px) !important;
}

.active-link {
  color:#e15303 !important;
}

.active-link:visited {
  color:#e15303 !important;
}

.show {
  display: flex;
}

.dropdown-toggle {
  background-color: inherit;
  border: none;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 4px;
}

button .arrow {
  position: relative;
  bottom: 2px;
  left: 4px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.changeImage{
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  opacity: 0.6;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.changeImage .arrow {
  top: 2px;
  left: 0px;
}

.changeImageLeft{
  top: 270px;
}

.changeImageRight{
  top: 270px;
  left: 530px;
}

.changeImage > .arrow {
  width: 6px;
  height: 6px;
}

.kataloguButtons{
  margin-bottom: 50px;
  width: 916px;
}

.OrderOpen{
  padding-right: 108px;
}

.kataloguButtons .katalogasButton{
  max-width: 211px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.kataloguButtons #techButton{
  max-width: 320px;
  margin-left: 0px;
  /* padding: 12px 0px;
  padding-left: 12px; */
}
.MobileDataMore{
  display: none;
}
.informacijaMobile{
  display: none;
}
.informationButtonMobile{
  display: none;
}
.circleExclamation{
  position: relative;
  top: 3px;
  width: 15px;
  height: 15px;
  padding-right: 5px;
  filter: invert(39%) sepia(91%) saturate(3826%) hue-rotate(10deg) brightness(97%) contrast(98%);
}
.circleExclamationBigger{
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;
  padding-right: 5px;
  filter: invert(39%) sepia(91%) saturate(3826%) hue-rotate(10deg) brightness(97%) contrast(98%);
}
.ExtraPajungimas .katalogasButton{
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0;
}
.ExtraDegiklis .katalogasButton{
  
  padding-top: 12px;
  padding-bottom: 12px;
}
.ExtraDegiklis .fileIcon{
  width: 22px;
  height: 30px;
}
.orderCompleteSection{
  padding-left: 150px;
  background-color: #f5f5f5;
  height: 700px;
}
.orderCompleteSection .Nav{
  top: 0px;
  left: 0px;
  float: left;
  min-width: 90%;
  padding-right: 80px;
}
.orderCompleteSection .MainInfo{
  padding-top: 50px;
}
.orderCompleteTekstas{
  padding-top: 125px;
  padding-bottom: 70px;
  font-size: 18px;
  font-weight: 600;
}
.orderCompleteSection .CloseOrderButton{
  position: absolute;
  padding-top: 50px;
  left: 85%;
}
.descriptionExtraPixels2{
  height: 700px;
}
.descriptionExtraPixels3{
  height: 460px;
}
.descriptionExtraPixelsTalpa2{
  padding-bottom: 100px;
}
.reserveKatilas{
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-left: 20px;
}
.unfinishedKatilas{
  display: flex;
  padding-bottom: 10px;
}
.reserveKatilai{
  padding-left: 100px;
  padding-top: 00px;
}
.reserveKatilai > h2{
  width: auto;
  padding-bottom: 27px;
}
.nav_underline{
  min-width: 30px;
}
.reserveForma form{
  display: flex;
  flex-direction: column;
  width: 200px;
}
.cardDetailsButton{
  background-color: #f5f5f5;
  position: relative;
  top: -55px;
}
.form900info{
  display: none;
}
.separatePadding{
  height: 40px;
}

.headerLogoImageSmall{
  height: 30px;
  /* width: 144px; */
  
  padding-bottom: 5px;
}

.policyBold:hover{
  color: #e15303;
  cursor: pointer;
}

.policyBold{
  color: black;
  position: relative;
  left: 6px;
  font-weight: 700;
}

.region-search-div .arrow{
  position: relative;
  bottom: 40px;
  left: 555px;
  z-index: 1;
}

.specs-dropdown-div .arrow{
  position: relative;
  bottom: 40px;
  left: 555px;
  z-index: 1;
}

.representativesMain.specsPage{
  padding-bottom: 80px;
}

.region-search-div{
  font-family: 'Poppins', sans-serif;
}

.categoryBoxes{
  /* margin-top: 100px; */
  display: flex;
}

.categoryBox:first-child{
  border: none;
  padding-left: 0;
}

.categoryBox{
  width: 310px;
  height: 220px;
  border-left: 1px solid gray;
  padding-left: 50px;
  min-width: 310px;
}

.categoryName{
  font-size: 24px;
}

.categoryImage{
  margin-bottom: 10px;
}

.categoryImageBox{
  height: 63px;
}

.categoryButton{
  margin-top: 60px;
  width: 260px;
  height: 55px;
  background-color: #D9D9D9;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.twoBoxes{
  display: flex;
  padding-left: 50px;
}

.twoBoxes:first-child{
  border-right: 1px solid gray;
  padding-left: 0;
}
.twoBoxes:last-child{
  border-right:none;
}

.resultsDiv .categoryButton{
  margin-top: 0;
  margin-left: 50px;
  width: 306px;
  display: block;
}

.resultsDiv{
  margin-top: 80px;
  margin-bottom: 120px;
}

.resultsDiv .ImgNumber{
  align-self: center;
}

.categoryButton.active{
  width: 260px;
  height: 55px;
  background-color: #e15303;
  color: white;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0px 0px 16px 1px #e15303;
  border: none;
  cursor: pointer;
}

.specs-dropdown-divs{
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
}

.specs-dropdown-div:first-child{
  margin-right: 100px;
}

.App  .header_katilu_rusys_real{

  z-index: 2001;
  top: 72px;
  background-color: white;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 100px;
  left: 280px;
}
.App  .header_katilu_rusys_real > a{
  color: black;
  padding-right: 30px;
}
.App .header_katilu_rusys_real > a:hover{
 color:#e15303;
}
.App > .header_katilu_rusys{
  z-index: 2000;
  position: sticky; 
  top: 0px;
  padding-left: 100px;
  
  background-color: white;
  padding-bottom: 6px;
  padding-top: 15px;
}
.App > .header_katilu_rusys > a{
  color: black;
}
.invisible{
  position: relative;
  top: -80px;
}
.invisibleMore{
  position: relative;
  top: -190px;
}
.klientamsIcon{
  position: relative;
  top: 2px;
  height: 14px;
  width: 12px;
  filter: invert(39%) sepia(91%) saturate(3826%) hue-rotate(10deg) brightness(97%) contrast(98%);
}
.klientamsIconGray{
  position: relative;
  top: 2px;
  height: 14px;
  width: 12px;
  filter: invert(74%) sepia(0%) saturate(0%) hue-rotate(163deg) brightness(92%) contrast(88%);
  z-index: -1;
}
.klientamsGray{
  color:#a7a7a7;
  padding-bottom: 50px;
  padding-top: 20px;
  font-size: 12px;
  font-weight: 600;
}
.headerLogoImageLink{

  width: 100%;
  padding-top: 30px;
  background-color: white;
  z-index: 3001;
}
.headerLogoImageLink .headerLogoImageSmall{
  padding-left: 100px;
}
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 400px;
  border: 5px dashed rgb(117, 112, 112);
  padding: 2rem;
}
.dropzone button, .uploads button{
  padding: 1rem;
  margin: 1rem;
  font-size: medium;
}
.uploads{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px; 
  font-size:larger;
}
.katiloInfoReserve{
  display: flex;
  flex-direction: row;
}
.katiloInfoReserve h3{
  padding-left: 10px;
  width: 220px;
}
.katiloInfoReserve span{
  padding-left: 10px;
  width: 150px;
  align-self: center;
}
.katiloInfoReserve input{
  margin-left: 10px;
  width: 40px;
}
.unfinishedKatilas h3{
  padding-left: 10px;
  width: 300px;
}
.unfinishedKatilas input{
  width: 40px;
}
.orderColumns{
  display: flex;
}
.cartBox{
  width: auto;
  min-width: 400px;
}
.cartBox li{
  display: flex;
  border-bottom: solid 1px black;
  align-items: center;
}
.cartBox li:last-child{
  border: none;
}
.cartBox li{
  text-justify: center;
}
.orderDeleteButton{
  border: none;
  background: transparent;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.orderDeleteButton img{
  width: 14px;
  height: 16px;
  filter: invert(77%) sepia(0%) saturate(45%) hue-rotate(142deg) brightness(86%) contrast(93%);
}
.katiloUzsakymoName{
  font-size: 24px;
  font-weight: 600;
  width: 400px;
}
.reserveForma hr{
  display: block;
  width: 90%;
  border-top: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.reserveKatilai hr{
  display: block;
  width: 90%;
  border-top: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.reserveKatilai h1{
  font-size: 36px;
  font-weight: 600;
}
.reserveKatilai h3{
  font-size: 16px;
  font-weight: 600;
}
.reserveKatilai h2{
  font-size: 24px;
  font-weight: 600;
}
.reserveKatilai .katiloGroupName{
  font-size: 24px;
  font-weight: 500;
}
.reserveKatilai .katiloKomplektacijosName{
  font-size: 24px;
  font-weight: 500;
}
/* Chrome, Safari, Edge, Opera  removing arrows*/
.reserveKatilai input::-webkit-outer-spin-button,
.reserveKatilai input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox removing arrows*/
.reserveKatilai input[type=number] {
  -moz-appearance: textfield;
}
.katiloInfoReserve input{
  text-align: center;
  color: #666666;
  height: 41px;
  width: 50px; 
}
.reserveKatilai .DetailsButton{
  height: 55px;
  width: 260px;
  background-color:#e15303;
  border: none;
  color: white;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
}
.orderNumberEmail{
  padding-bottom: 150px;
}
.unfinishedKatilas .unfinishedOrange{
  color: #e15303;
  align-self: center;
  width: 400px;
}
.reserveForma .prisijungtiButton{
  border: none;
  font-size: 16px;
  font-weight: 600;
  width: 260px;
  height: 55px;
}
.inputTextSearch{
  width: 380px;
  height: 53px;
  border: 1px solid #000000;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
}
.reserveForma input {
  width: 370px;
  height: 55px;
}
.reserveForma .inputTextLogin, .reserveForma .inputTextPass{
  border: 1px solid #000000;
  margin-bottom: 50px;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 600;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.reserveForma .inputTextLogin::placeholder{
  opacity: 1;
  color: #000000;
  text-indent: 10px;
}

.reserveForma{
  padding-left: 100px;
}
.reserveForma h1{
  padding-bottom: 100px;
  font-size: 36px;
  font-weight: 600;
}
.sveikiUzsakovas{
  display: flex;
  width: 100%;
}
.sveikiUzsakovas button{
  margin: 0;
  align-self: center;
  margin-left: 100px;
}
.forBoxShadow{
  box-shadow: 0px 0px 20px #00000033;
}
.categoryRadioDiv{
  display: none;
}
.dateOrderPickers{
  display: flex;
  flex-direction: row;
}
/* .dateOrderPickers .react-datepicker-wrapper{
  width: 150px;
  padding: 10px 30px 20px 0px;
} */
.dateOrderPickers input{
  color: #e15303;
  font-size: 20px;
  width: 150px;
  border: none;
  border-bottom: solid 1px #000000;
}
.reserveKatilai .dropdown-div{
  left: auto;
  top: auto;
  padding-bottom: 70px;
}
.reserveKatilai .dropdown-item{
  background-color:transparent;
  border: none;
  padding-left: 50px;
  font-size: 18px;
  font-weight: 600;
  border-top:1px solid #a7a7a7;
}
.reserveKatilai .dropdown-item:first-child{
  border-top: none;
}
.reserveKatilai .dropdown-menu{
  background-color: white;
}
.loginError{
  display: flex;
  flex-direction: row;
  color: #e15303;
  font-size: 12px;
  font-weight:500;
  width: 400px;
  padding-bottom: 10px;
}
.loginError img{
  padding-right: 10px;
  width: 18px;
  height: 18px;
  filter: invert(39%) sepia(91%) saturate(3826%) hue-rotate(10deg) brightness(97%) contrast(98%);
}
.FormSelects .dropdown-div{
  left: auto;
  top: auto;
  width: 475px;
  z-index: auto;
}
.FormSelects .dropdown, .FormSelects .dropdown > #dropdown-basic{
  width: 475px;
}
.FormSelects .dropdown > #dropdown-basic{
  padding: 0;
}
.FormSelects .dropdown-div .dropdown{
  border: none;
  border-bottom: solid 1px #666666;
}
.FormSelects .dropdown .arrow{
  margin: 0;
  left: auto;
  right: 5px;
}
.FormSelects .dropdown-menu{
  width: 475px;
}
.FormSelects .dropdown-item{
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 5px;
  border-bottom: solid 1px #666666;
}
.FormSelects .dropdown-item:last-child{
  border-bottom: none;
}
.excludeGreyLogo h1{
  font-size: 36px;
  font-weight: 600;
}
.klientamsLinkDiv a:hover {
  color: #e15303;
}
.klientamsLinkDiv a {
  color: black;
  font-size: 12px;
  white-space: nowrap;
}
.contactInfo hr{
  display: block;
  width: 100%;
  border: 0;
  border-top: 1px solid #a7a7a7;
  margin-top: 30px;
  margin-bottom: 30px;
}
.additionalPrekCentrasLink{
  padding-top: 50px;
}
.additionalPrekCentrasLink a{
  color: #e15303;
}
.opacity50{
  opacity: 0.5;
}
.prekCentraiRadioButtons{
  display: flex;
  padding-top: 50px;
  /* align-items: center;
  justify-content: center; */
}
.prekCentraiRadioButtons .prekCentrasDiv:first-child{
  border: none;
  padding-left: 0;
}
.prekCentrasDiv img{
  padding-left: 20px;
  object-fit: none;
  height: auto;
  width: auto;
}
.prekCentraiRadioButtons .prekCentrasDiv{
  width: 310px;
  height: 60px;
  border-left: 1px solid gray;
  padding-left: 50px;
  min-width: 310px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.prekCentrasDiv .categoryRadioButton{
  height: 18px;
  width: 18px;
  accent-color: #e15303;
}
.katilaiSandelyjeTable input{
  width: 110px;
  height: 42px;
  border: 1px solid black;
  border-radius: 0px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  padding-right: 20px;
  color: #666666;
  border-bottom: none;
  border-top: none;
}
.katilaiSandelyjeTable{
  border-collapse:collapse;
  text-align: center;
}
.katilaiSandelyjeTable th:nth-child(2){
  padding-left: 30px;
}
.katilaiSandelyjeTable td:nth-child(2){
  padding-left: 30px;
}
.katilaiSandelyjeTable th{
 padding-left: 15px;
 padding-right: 15px;
 font-size: 14px;
 font-weight: 500;
 padding-bottom: 9px;
}
.katilaiSandelyjeTable td{
  padding-top: 13px;
  padding-bottom: 7px;
  text-align: start;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 600;
}
.katilaiSandelyjeTable td:last-child{
  padding: 0;

  border: 1px solid white;
  position: relative;
  
}
.katilaiSandelyjeTable th:last-child{
  padding-right: 0;
  padding-left: 0;
  color: #e15303;
}
.katilaiSandelyjeTable td:first-child{
  text-align: start;
  padding-right: 30px;
  padding-left: 0px;
}
.katilaiSandelyjeTable th:first-child{
  padding-right: 30px;
  padding-left: 0;
}
.katilaiSandelyjeTable th:first-child, .katilaiSandelyjeTable td:first-child{
  border-right:1px solid #a7a7a7;
  text-align: start;
}
.katilaiSandelyjeTableHead{
  border-bottom: 1px solid #a7a7a7;
}
.katilaiSandelyjeTable tbody tr{
  border-bottom: 1px solid #a7a7a7;
  
}
.katilaiSandelyjeTable tbody tr:last-child{
  border-bottom: none;
}
.uzsakymasUp{
  position: absolute;
  height: 22px;
  width: 20px;
  margin-top: 3px;
  border: none;
  background-color: transparent;
}
.uzsakymasDown{
  position: absolute;
  height: 22px;
  width: 20px;
  margin-top: 19px;
  border: none;
  background-color: transparent;
}
.uzsakymasUp:hover, .uzsakymasDown:hover{
  cursor: pointer;
}
.uzsakymuIstorijaLink{
  display: flex;
  align-self: flex-end;
  
  padding-left: 50px;
  height: 50px;
  border-left: 1px solid #666666;
}
.uzsakymuIstorijaLink button{
  border: none;
  background-color: transparent;
  align-self: flex-end;
  position: relative;
  top: 7px;
  font-size: 16px;
  font-weight: 600;
  color: #e15303;
  margin:0;
}
.uzsakymuIstorijaLink span{
  position: relative;
  top: 4px;
  padding-left: 30px;
  font-size: 24px;
}
.sveikiUzsakovas div{
  padding-right: 50px;
}
.uzsakymuIstorijaButton{
  height: 55px;
  width: 260px;
  background-color: #d9d9d9;
  border: none;
  color: black;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
}
.uzsakymuIstorijaButton:hover{
  cursor: pointer;
}
.uzsakymuIstorijaButton:active{
  background-color: #666666;
}
.katilaiOrderGroupTitle{
  color:#e15303;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}
.uzdarytiOrderHistory .uzsakymuIstorijaButton{
  margin-top: 115px;
}
.reserveKatilai .uzsakymuIstorijaTitle{
  font-size: 24px;
  font-weight: 600;
}
.uzsakymuIstorijaSearchDiv{
  display: flex;
  padding-top: 50px;
}
.uzsakymuIstorijaSearchDiv .uzsakymuIstorijaButton{
  margin: 0;
  margin-left: 30px;
}
.react-datepicker__triangle, .react-datepicker__day-names{
  display: none;
}
.react-datepicker__navigation-icon::before{
  border-color: #000;
}
.datePickerHeaderCustom{
  display: flex;
  justify-content: space-between;
}
.datePickerHeaderCustom button{
  border: none;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
}
.datePickerHeaderCustom .arrow{
  left: 0;
  margin-left: 0;
}
.dateOrderPickers .react-datepicker__header{
  background-color: white;
  border-bottom: 1px solid black;
}
.dateOrderPickers .react-datepicker{
  border-radius: 0;
  border: 1px solid black;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
}
.dateOrderPickers .react-datepicker__month{
  margin-left: 86px;
  margin-right: 86px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.dateOrderPickers .react-datepicker__week .react-datepicker__day--selected,
/* .dateOrderPickers .react-datepicker__week .react-datepicker__day--in-selecting-range, */
.dateOrderPickers .react-datepicker__week .react-datepicker__day--in-range,
.dateOrderPickers .react-datepicker__week .react-datepicker__day--outside-month:is(.react-datepicker__day--selected){
  color: black;
}
/* .dateOrderPickers .react-datepicker__week .react-datepicker__day--selecting-range-start{
  color: #e15303;
} */
.dateOrderPickers .react-datepicker__day{
  color: #a7a7a7;
  background-color: transparent !important;
  border: none !important;
}
.datePickerCustomButton{
  background-color: transparent;
  border-radius: 0;
  border: 1px solid black;
  width: 400px;
  height: 53px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.datePickerCustomButton:hover{
  cursor: pointer;
}
.datePickerCustomButton span{
  margin-left: 20px;
}
.datePickerCustomButton .arrow{
  margin-right: 20px;
}
.dateOrderPickers .react-datepicker-wrapper{
  width: auto;
}
.datePickerWithTitle{
  display: flex;
  flex-direction: column;
}
.datePickerWithTitle:first-child{
  margin-right: 32px;
}
.datePickerWithTitle > span{
  padding-bottom: 10px;
}
.dateOrderPickers{
  margin-top: 50px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 80px;
}
.uzsakomasKiekisTd:is(td){
  padding: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.orangeText{
  color:#e15303;
}
.secondKatilaiOrderGroupTitle{
  padding-top: 50px;
}
.thirdKatilaiOrderGroupTitle:is(h3){
  padding-top: 50px;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}
.katilaiSandelyjeTable .orderAtliktasTd:is(td){
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}
.extraBorders{
  border-right: 1px solid #a7a7a7;
  border-left: 1px solid #a7a7a7;
}
.katilaiSandelyjeTable .orderAtliktasTh:is(th){
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
}
.orderInfoAndButton{
  margin-top:50px;
}
.headerRemakeV2{
  position: sticky;
  top: 0px;
  z-index: 3001;
}
.reserveForma .prisijungtiButton:hover{
  background-color: #D9D9D9;
}
.reserveForma .prisijungtiButton{
  background-color: #D9D9D9;
}
.reserveForma .prisijungtiButton:active{
  background-color: #666666;
}
.uzsakymuIstorijaLink button:hover{
  cursor: pointer;
}
.pelletUniStarInfo{
  font-size: 16px;
  font-weight: 500;
}
.loadingPage{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  z-index: 4000;
  background-color: white;
  pointer-events: none;
}
.loadingPageInnerDiv{
  display: flex;
  flex-direction: column;
}
.loadingPageInnerDiv span{
  padding-top: 60px;
  font-size: 12px;
  font-weight: 600;
}
#loadingDivId {
  opacity: 1;
  transition: opacity 1s; 
}
#loadingDivId.fadeForLoading{
  opacity: 0;
}
.advert{
  padding-left: 10%;
  padding-right: 10%;
}
.advert h1{
  color: #e15303;
}

.esParamP2{
  padding-top: 20px;
  padding-bottom: 30px;
}

.esParamImgs img{
  height: 75px;
  padding-right: 26px;
}

.esParamImgs{
  padding-bottom: 40px;
}

.katiluParamP{
  padding-bottom: 60px;
}

.EnaLink{
  color: #e15303;
}

/* .arrow{
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 4px;
} */
/* .nameAndSelect{
  display: flex;
}
.repListTitle.nameAndSelect h1{
  padding: 0;
} */
/* BREAKPOINTS */
/* 1900px */
/* 1800px */
/* 1600px */
/* 1450px */
/* 1366px */
/* 1300px */
/* 1170px */
/* 1100px */
/* 1000px */
/* 900px */
/* 800px */
/* 600px */
/* 500px */
/* 393px */

@media only screen and (max-width: 1900px) {
  
  .greyLogo{
    display: none;
  }
  .ExtraTabs > .Price {
    left: 0px;
    /* top: -284px; */
    top: -0px;
  }
  .katilai{
    display: grid;
  }
  .cards{
    justify-self: center;
    grid-template-columns: 1fr 1fr;
    width: 1100px;
  }
  .card{
    width: 552px;
    height: 741px;
  }
  .card img{
    height: 741px;
  }
  .middleText{
    display: none;
  }
  .nonTitleInfoContent{
    padding-top: 20px;
  }
  .column{
    width: 33%;
  }
  .OrderRight .DetailsBigData{
    margin-top: 20px;
  }
  .iconsDivDown{
    margin-top: 50px;
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 1893px) {
  .OrderRight{
    border-top: 1px solid #707070;
  }
  .DetailsBigData{
    margin-top: 20px;
  }
  .DetailsRight {
    padding-top: 10px;
    margin-top: 20px;
    border-top: 1px solid #707070;
  }
  .OrderRight {
    padding-top: 10px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 1800px) {
  .ExtraButtonsUnderline {
    width: 35%;
    top: 61px;
  }
  .descriptionExtraPixels2{
    height: 700px;
  }
}
@media only screen and (max-width: 1680px) {
  .header_katilu_rusys_real{
    display: none;
  }
  .ExtraTabs > .Price{
    top: -236px;
  }
}
@media only screen and (max-width: 1600px) {
  .prekCentraiRadioButtons .prekCentrasDiv{
    border: none;
    width: auto;
    min-width: auto;
    height: 30px;
    padding-left: 40px;
  }
  .prekCentraiRadioButtons .prekCentrasDiv img{
    height: 35px;
    object-fit:scale-down;
    max-width:150px;
  }
  .ExtraButtonsUnderline {
    width: 40%;
    top: 62px;
  }
  .categoryBoxes{
    margin-top: 30px;
  }
  .categoryButton, .categoryName{
    display: none;
  }
  .categoryRadioButton{
    height: 18px;
    width: 18px;
    accent-color: #e15303;
  }
  .specs-dropdown-divs{
    flex-direction: column;
    row-gap: 20px;
  }
  .categoryBox{
    height: auto;
    min-width: auto;
    width: auto;
    border: none;
    padding-left: 40px;
  }
  .twoBoxes, .twoBoxes:first-child{
    border: none;
  }
  .twoBoxes{
    padding-left: 40px;
  }
  .categoryImage, .categoryImageBox{
    padding-left: 15px;
    height: 30px;
  }
  .categoryRadioDiv{
    display: flex;
  }
  .categoryRadioDiv input{
    align-self: center;
  }
  .categoryRadioDiv label{
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    padding-left: 10px;
    align-self: center;
  }
}
@media only screen and (max-width: 1500px) {
  
}
@media only screen and (max-width: 1410px) {
  .ExtraButtonsUnderline{
    width: 35%;
  }
  .ExtraTabs .Price {
    left: 800px;
  }
}
@media only screen and (max-width: 1380px) {
  #bigAboutHeader.black_tree_links{
    right: 130px;
  }
}
@media only screen and (max-width: 1366px) {
  .ExtraButtonsUnderline {
    width: 34%;
    top: 62px;
  }
}
@media only screen and (max-width: 1300px) {
  .cardDetailsButton{
    background-color: transparent;
    top: auto;
  }
  .advert{
    display: none;
  }
  .katilai{
    display: block;
  }
  .cards{
    width: auto;
  }
  table .BigData{
    text-align: start;
  }
  .ExtraTabs{
    flex-direction: column;
  }
  .ExtraAprašymas > p {
    max-width: 600px;
    text-align: left;
  }
  .ExtraDegiklis > p {
    max-width: 700px;
    text-align: left;
  }
  .ExtraTabs .Price {
    align-self: auto;
    min-width: 400px;
    position: relative;
    left: 700px;
  }
  .DetailsRight .Price {
    top: 40px;
  }

  .link-div{
    height: 80px;
    position: relative;
    bottom: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .link-div > .OrangeLink{
    width: 400px;
  }

  .DetailsRight .Price .OrangeLink{
    top: 0px;
  }
  
  .GrayTableData{
    font-size: 12px;
  }
  .tableWho td{
    font-size: 12px;
  }
  .DataTableHeader{
    font-size: 14px;
  }
  .OrderRight > div:first-child{
    margin-top: 8px;
  }
  .IconsDiv{
    height: 80px;
    padding-bottom: 10px;
  }
  .IconBox{
    height: 100%;
  }
  .Icon{
    height: 100%;
  }
  .descriptionExtraPixels1{
    height: 410px;
  }
  .descriptionExtraPixels2{
    height: 700px;
  }
  .descriptionExtraPixels4{
    height: 300px;
    min-height: 300px;
  }
  /* .kataloguButtons{
    display: grid;
    grid-template-columns: 230px 230px 230px 230px;
    grid-row-gap: 4px;
    grid-column-gap: 4px;
    margin-bottom: 0;
  } */
  .kataloguButtons .katalogasButton{
    
    font-size: 14px;
    
  }
  .DetailsSection{
    margin-bottom: 50px;
  }
  .ExtraButtonsUnderline{
    display: none;
  }
  .card{ 
    padding-left: 0;
    margin-bottom: 8px;
    background-color: #F5F5F5;
    width: 100%;
    height: fit-content;
  }
  .Nav{
    display: none;
  }
  .NavAndEff .Nav{
    display: block;
  }
  .card > img{
    object-fit: cover;
    width: 628px;
    height: 500px;
    margin-top: 140px;
  }
  .card .DetailsButton{
    position: relative;
    margin-top: 80px;
    left: 80px;
  }
  .mainFeaturesTable{
    top: 83%;
  }
  .cards{
    display: flex;
    flex-direction: column; 
    padding-left: 0;
  }
  .main-katilai{
    display: none;
  }
  .dropdown-div{
    left:80px;
    top:0; 
  }
  .DetailsBigData{
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 1170px) {
  .ExtraTabs .Price{
    top: 10px;
  }
  .ExtraPajungimas div{
    max-width: 400px;
  }
  .ExtraPajungimas div div{
    border-left: 0px;
    padding-left: 0px;
    margin-left: 10px;
    margin-top: 6px;
    height: 30px;
  }
  .ExtraPajungimas .katalogasButton{
    padding: 14px 28px;
  }
  form .Price {
    bottom: 30px;
    left: 40%;
  }
  .ExtraTabs .Price {
    left: 56%;
  }
}
@media only screen and (max-width: 1100px) {
  .tableWho{
    max-width: 780px;
  }
  .DetailsRight .Price {
    left: 0;
  }
  /* .kataloguButtons{
    grid-template-columns: 230px 230px;
  } */
  .CloseOrderButton {
    left: 28%;
  }
  .FormInputFields {
    max-width: 900px;
  }
  .InputFieldRow{
    width: 740px;
  }
  .InputFieldDiv input {
    width: 360px;
  }
  .InputFieldDiv .asterisk {
    left: 350px !important;
  }
  .TextAreaFieldDiv textarea{
    max-width: 740px;
    width: 740px;
  }
}
/* @media only screen and (max-width: 1020px) {
  .kataloguButtons{
    width: 750px;
  }
} */
@media only screen and (max-width: 1000px) {
  .CloseOrderButton {
    left: 19%;
  }
  form .Price {
    left: 0%;
  }
  .ExtraTabs > .Price {
    left: 50%;
    right: 0;
    align-self: auto;
  }
}
@media only screen and (max-width: 900px) {
  .pelletUniStarInfo{
    color: grey;
    padding-top: 40px;
    font-size: 14px;
  }
  .prekCentraiRadioButtons{
    flex-direction: column;
  }
  .prekCentraiRadioButtons .prekCentrasDiv{
    padding-left: 0;
    padding-top: 20px;
    justify-content: flex-start;
  }
  .categoryBoxes{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .twoBoxes:first-child{
    padding: 0;
  }
  .twoBoxes{
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 20px;
  }
  .categoryBox:first-child{
    padding: 0;
  }
  .categoryBox{
    padding: 0;
    padding-top: 20px;
  }
  .form900info{
    position: absolute;
    display: block;
  }
  .ExtraMatmenys > .talpaTableMobile {
    max-width: 140px;
  }
  .ExtraMatmenys > .talpaTableMobile th {
    min-width: 60px !important;
  }
  .changeImageLeft{
    position: absolute;
    top: 214px;
  }
  .changeImageRight{
    position: absolute;
    top: 214px;
    left: 530px;
  }
  .form900info .katilasName{
    font-size: 24px;
  }
  .form900info .dataTop{
    font-size: 12px;
  }
  .CloseOrderButton p {
    display: none;
  }
  .OrderOpen{
    display: none;
  }
  .OrderRight{
    border-top: 0px;
  }
  .DetailsBottom{
    padding-bottom: 0;
    min-height: 40px;
  }
  .card .DetailsButton{
    left: auto;
  }
  .descriptionExtraPixels2.true{
    max-height: 40px;
  }
  .descriptionExtraPixels1.true{
    height: 40px;
  }
  .descriptionExtraPixels3.true{
    height: 40px;
  }
  .descriptionExtraPixels4.true{
    height: 40px;
  }
  .descriptionExtraPixels2.false{
    max-height: 40px;
  }
  .descriptionExtraPixels1.false{
    height: 40px;
  }
  .descriptionExtraPixels3.false{
    height: 40px;
  }
  .descriptionExtraPixels4.false{
    height: 40px;
  }
  .DetailsBottom.true{
    min-height: 40px;
  }
  .KuroTipai tr{
    border-bottom: 1px solid #707070;
    padding-top: 14px;
  }
  .KuroTipai tr:last-child{
    border-bottom: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .FormInputFields{
    margin-top: 14px;
  }
  .FormSelects select{
    width: 95%;
  }
  .InputFieldRow{
    width: 95%;
    flex-wrap: wrap;
    row-gap: 30px;
  }
  .ExtraTabs > .Price {
    left: 0%;
  }
  .TextAreaFieldDiv textarea{
    max-width: 96.1%;
    width: 96.1%;
  }
  .InputFieldDiv input{
    width: 100%;
  }
  .InputFieldDiv{
    width: 100%;
  }
  .InputFieldDiv .asterisk{
    left: 98% !important;
  }
  .BigData{
    display: none;
  }

  .DetailsRight {
    border-top: none;
    padding-top: 0px;
  }
  .Circle{
    display: none;
  }
  .CloseOrderButton {
    left: 105%;
    right: 0%;
    top: -85px;
  }
  .OrangePDiv{
    width: 100%;
  }
  .BetweenTables{
    display: none;
  }
  .DetailsButton{
    position: relative;
    align-self: center;
  }
  .card{ 
    padding-left: 0;
    margin-bottom: 8px;
    align-self: center;
    background-color: #F5F5F5;
    width: 100%;
    height: fit-content;
  }
  .card img{
    object-fit: cover;
  }
  .card > img{
    width: 628px;
    height: 500px;
    align-self: center;
    margin-top: 120px;
  }
  .cardDetailsButton{
    margin-left: 100px;
    margin-right: 100px;
  }
  .card .DetailsButton{
    align-self: center;
    position: relative;
    margin-top: 80px;
    width: 100%;
  }
  .mainFeaturesTable{
    top: 84%;
  }
  .cards{
    display: flex;
    flex-direction: column; 
    padding-left: 0;
  }
  .tableWho{
    display: none;
  }
  .DetailsRight > .AdditionalDataTable{
    display: none;
  }
  .Wrapper{
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
  }
  table .Fuel2 {
    border-right: none;
    border-left: 1px solid gray;
  }
  table .Fuel1 {
    border-right: none;
    border-left: none;
  }
  .NavAndEff .Nav{
    left: 100px;
  }
  .desktopImage{
    display: none;
  }
  .mobileImage{
    display: inherit;
  }
  .IconsDiv{
    display: none;
  }
  .DetailsBigData{
    display: none;
  }
  .ExtraButtons{
    display: none;
  }
  .ConditionalDivs{
    display: none;
  }
  .DetailsBottom{
    padding-left: 0;
    margin-top: 0;
  }
  .kataloguButtons{
    display: flex;
    flex-direction: column !important;
    width: 100%;
    margin-bottom: 0;
  }
  .kataloguButtons .katalogasButton{
    border: none;
    padding-left: 0;
  }
  /* .kataloguButtons .katalogasButton, .kataloguButtons #techButton{
    margin-top: 4px;
    width: 100%;
    max-width:100%;
    padding: 14px 10px 14px 10px;
  } */
  .DetailsLeft img{
    width: 100%;
    height: 100%;
    align-self: center;
  }
  .DetailsLeft{
    padding-right: 0;
  }
  .ExtraTabs {
    position: relative;
    top: 0;
    width:auto;
    padding-left: 100px;
    padding-right: 100px;
  }
  .ExtraTabs > .Price {
    width: 100%;
  }
  .Price .DetailsButton{
    width: 102.9%;
    margin-bottom: 6px;
  }
  .Price > .OrangeLink {
    top: -18px;
    right: 0px;
  }
  .DetailsSection{
    padding-bottom: 0;
    margin-bottom: 0px;
  }
  .katilasImageDiv{
    padding-left: 0%;
    padding-right: 0%;
  }
  .KuroTipai tr{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .KuroTipai tr > td{
    padding-left: 0px !important;
    border: none !important;
  }
  .KuroTipai tr > div{
    text-align: left;
  }
  .KuroTipai tr > div > td:first-child{
    padding-left: 0 !important;
  }
  .MobileBigText{
    position: relative;
    bottom: 60px;
    left: 6px;
    font-weight: 600;
  }
  .MobileSmallText{
    position: relative;
    bottom: 32px;
    right: 44px;
    font-size: 10px;
    line-height: 1.2em;
    max-width: 40%;
    display: inline-block;
  }

  .MobileDataMore > div:last-child > .MobileSmallText{
    bottom: 34px;
    right: 44px;
  }

  table .BigDataMobile{
    display: flex;
    flex-direction: column;
  }
  .visibleClass{
    /* TODO hide everything else in some other way */
    width: 100% !important;
    height: 926px !important;
  }
  .KuroTipai tbody{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .EffClassBigMobile {
    position: relative;
    top: 0px;
  }
  .EffClassTextMobile {
    position: relative;
    color: #666666;
    font-size: 12px;
  }
  .KuroTipai hr, .informacijaMobile hr{
    width: 100%;
  }
  table .BigNumber {
    font-size: 64px;
    position: relative;
    bottom: 20px;
  }
  table .BigText {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    left: 52px;
    top: 0px;
  }
  table .SmallText {
    font-size: 10px;
    font-weight: 700;
    position: relative;
    left: 54px;
    top: 24px;
    line-height: 1.2em;
  }
  .MobileDataMore{
    display: flex;
    flex-direction: row;
    max-height: 40px;
    margin-top: 6px;
    margin-bottom: 8px;
  }
  .informacijaMobile > div {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .MobileDataMore hr{ 
    border: none;
    border-left: 1px solid black;
    height: 60px;
    width: 1px;
    position: relative;
    bottom: 34px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .MobileDataMore img{
    padding-right: 30px;
  }
  .informacijaMobile{
    display: flex;
    flex-direction: column;
  }
  .informacijaMobile .ExtraMatmenys img{
    width: 100%;
    height: 100%;
  }
  .informacijaMobile .ExtraAprašymas {
    border-bottom: 1px solid #707070;
    padding-bottom: 20px;
  }
  .informacijaMobile .ExtraAprašymas > h3 {
    margin-bottom: 16px;
    font-weight: 600;
  }
  .informacijaMobile .ExtraMatmenys > h3 {
    margin-bottom: 16px;
    font-weight: 600;
  }
  .ExtraMatmenys {
    padding-bottom: 60px;
  }
  .ExtraMatmenys > table {
    max-width: 300px;
    width: 300px;
    font-size: 12px;
  }
  .ExtraMatmenys .DataTableHeader {
    font-size: 13px;
  }
  .informationButtonMobile{
    display: flex;
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .informationButtonMobile button{
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    background: none!important;
    border: none;
    padding: 0!important;
    color: #000;
  }
  .DetailsLeft .katilasImage {
    width: 600px;
    height: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .katilasImageDiv{
    display: flex;
    flex-direction: column;
  }
  .changeImageLeft{
    position: absolute;
    top: 214px;
  }
  .changeImageRight{
    position: absolute;
    top: 214px;
    left: 90%;
  }
  .Price .DetailsButton{
    width: 104%;
  }
  .DetailsLeft .katilasImage{
    width: 600px;
    height: 477px;
    align-self: center;
  }
  .mainFeaturesTable{
    top: 80%;
  }
  .card > img{
    width: 460px;
    height: 400px;
    align-self: center;
    margin-top: 120px;
  }
}
@media only screen and (max-width: 700px) {
  .changeImageLeft{
    position: absolute;
    left: -40px;
    top: 214px;
  }
  .specs-dropdown-div:first-child{
    margin-right: 0;
  }
  .specs-dropdown-div .arrow{
    left: 70%;
  }
  .specs-dropdown-div{
    width: 100%;
  }
  .changeImageRight{
    position: absolute;
    top: 214px;
    left: 100%;
  }
  .twoBoxes{
    flex-direction: column;
  }
  .region-search-div .arrow{
    left: 70%;
  }
  
}
@media only screen and (max-width: 600px) {
  .FormSelects .dropdown-menu, .FormSelects .dropdown-div, .FormSelects .dropdown, .FormSelects .dropdown > #dropdown-basic{
    width: 100%;
  }
  .cardDetailsButton{
    margin-left: 50px;
    margin-right: 50px;
  }
  .DetailsLeft .katilasImage{
    width: 400px;
    height: 318px;
    align-self: center;
  }
  .changeImageLeft{
    position: absolute;
    left: 0px;
    top: 144px;
  }
  .changeImageRight{
    position: absolute;
    top: 144px;
    left: 90%;
  }
  .smalltree
  .Price .DetailsButton{
    width: 105%;
  }
  .ExtraMatmenys > table {
    font-size: 11px;
  }
  .ExtraMatmenys .DataTableHeader {
    font-size: 12px;
    font-weight: 500;
  }
  .ExtraMatmenys .GrayTableData {
    font-size: 11px;
  }
  .cards {
    row-gap: 0px;
  }
  .FormSelects select {
    width: 95%;
    font-size: 16px;
  }
  .Nav {
    display: none;
  }
  .nav_underline{
    min-width: 22px;
  }
  .CloseOrderButton {
    left: 90%;
    right: 0%;
  }
  .mainFeaturesTable {
    left: 50px;
  }
  .dropdown-div{
    left: 50px;
  }
  .card > .feature {
    left: 50px;
  }
  
  .card > .katilasName {
    left: 50px;
  }
  .card > .dataTop {

    left: 50px;
  }
  .Wrapper{
    padding-left: 50px;
    padding-right: 50px;
  }
  .ExtraTabs {
    padding-left: 50px;
    padding-right: 50px;
  }
  .NavAndEff .Nav{
    left: 50px;
  }
  .TextAreaFieldDiv textarea{
    max-width: 96.75%;
    width: 96.75%;
  }
  .NavAndEff .order_nav{
    display: none;
  }
  .NavAndEff .Nav.Mobile_order_nav{
    display: inherit;
  }
}
@media only screen and (max-width: 500px) {
  .Price .DetailsButton{
    width: 105%;
  }
  .ExtraMatmenys > .talpaTableMobile {
    min-width: 70%;
  }
  .changeImageLeft{
    position: absolute;
    top: 140px;
    left: -7%;
  }
  .changeImageRight{
    position: absolute;
    top: 140px;
    left: 95%;
  }
  .ExtraMatmenys > table {
    max-width: 100%;
    /* width: 20%; */
    table-layout: fixed;
  }
  .ExtraMatmenys .DataTableHeader {
    max-width: 20%;
  }
  .OrderRight .OrangeLink{
    font-size: 14px;
  }
  .nav_underline{
    min-width: 16px;
  }
  .NavAndEff .Nav{
    padding-right: 20px;
  }
  .DetailsSection > .NavAndEff > .Nav > p span > a{
    font-weight: 600;
    font-size: 11px !important;
  }
  .ExtraTabs .Price{
    min-width: 100%;
  }
  .Price > .OrangeLink {
    position: absolute;
    top: 240px;
    right: 27%;
  }
  .CloseOrderButton {
    left: 90%;
    right: 0%;
  }
  html, body {
    max-width: 100%;
    overflow-x: hidden;
  } 
}
@media only screen and (max-width: 450px) {
  .Price .DetailsButton{
    width: 108%;
  }
}
@media only screen and (max-width: 400px) {
  .OrderRight .OrangeLink{
    right: 71px;
  }
  .specs-dropdown-div .arrow{
    left: 90%;
  }
  .card > img {
    width: 440px;
    height: 382px;
  }
  .DetailsLeft .katilasImage{
    width: 340px;
    height: 270px;
    align-self: center;
  }
  .ExtraMatmenys > .talpaTableMobile {
    min-width: 70%;
  }
  .changeImage{
    background-color: rgb(255, 255, 255, 0.3);
  }
  .changeImageLeft{
    position: absolute;
    top: 120px;
    left: -15%;
  }
  .changeImageRight{
    position: absolute;
    top: 120px;
    left: 100%;
  }
  .ExtraMatmenys > table {
    min-width: 110%;
  }
  .region-search-div .arrow{
    left: 86%;
  }
}
@media only screen and (max-width: 396px) {
  .FormInputFields > label > .asterisk{
    left: 72px !important;
  }
  .TextAreaFieldDiv textarea{
    max-width: 98%;
    width: 98%;
  }
}
@media only screen and (max-width: 393px) {
  .MobileDataMore > div:last-child > .MobileSmallText{
    bottom: 79px !important;
    right: -50px !important;
  }
}
@media only screen and (max-width: 390px) {
  .OrderRight .OrangeLink{
    position: absolute;
    left: 0px;
    font-size: 12px;
    width: 260px;
  }
  .link-div > .OrangeLink2{
    font-size: 14px;
  }
  .MainInfo .katilasName{
    font-size: 40px;
  }
}
@media only screen and (max-width: 325px) {
  .FormInputFields > label > .asterisk{
    left: 168px !important;
  }
}